@font-face {
  font-family: "Sharp";
  src: local("Sharp"), url(../fonts/Sharp.ttf);
}

@font-face {
  font-family: "Surt";
  src: local("Surt"), url(../fonts/Surt.otf);
}

.sharp {
  font-family: Sharp;
}

.surt {
  font-family: Surt;
}

.input {
  background: none;
  border: none;
  outline: none;
  text-align: center;
  padding: 0;
  color: #22272c;
}

.input::placeholder {
  color: #7b8084;
  opacity: 0.5;
}

/* Colors */

.section-color {
  background-color: #f5f5f5;
}

.text-color {
  color: #3b3f43;
}

.text-highlight {
  color: #7c7bb7;
}
